import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NavbarComponent from "../components/Navbar";
import SelectPriceScenariotab from "../components/SelectPriceScenariotab";
import AddFuelTab from "../components/AddFuelTab";
import RefinePriceTab from "../components/RefinePriceTab";
import { useSelector } from "react-redux";
import BackArrow from "../assets/arrow-left.svg";
import { getOwner } from "../api/data";
import { reactAppConfig } from "../config/reactAppConfig";

const FuelPriceForecast = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("v-pills-scenario");
  const [previousTabs, setPreviousTabs] = useState([]);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [backArrowLink, setBackArrowLink] = useState("");

  const handleNext = () => {
    const nextTabMap = {
      "v-pills-scenario": "v-pills-addfuel",
      "v-pills-addfuel": "v-pills-refineprice",
    };
    setPreviousTabs([...previousTabs, activeTab]);
    setActiveTab(nextTabMap[activeTab] || activeTab);
  };

  const handlePrevious = () => {
    const lastPreviousTab = previousTabs.pop();
    setActiveTab(lastPreviousTab || "v-pills-scenario");
    setPreviousTabs([...previousTabs]);
  };

  const isTabActive = (tabId) => tabId === activeTab;
  const isPreviousTab = (tabId) => previousTabs.includes(tabId);
  const permissions = useSelector((state) => state.permissions.permissions);
  const canAccessRoute = (requiredPermission) => {
    return permissions.some(
      (permission) =>
        permission.licensePermission.permissionName === requiredPermission
    );
  };

  const handleBackUrl = () => {
    const url = localStorage.getItem("appUrl")
    setBackArrowLink(url);
  }

  const fetchOwnerInfo = async () => {
    try {
      const result = await getOwner();
      setOwnerInfo(result.data);
    } catch (error) {
      console.log("Error fetching owner information:", error);
    }
  };

  useEffect(() => {
    fetchOwnerInfo();
  }, []);

  return (
    <div className="login-container h-100 splash-screen pb-5">
      <NavbarComponent showfaq={false} />
      <main className="container-padding">
        <div className="price-scenario-container">
          <div className="d-flex justify-content-between align-items-center">
          <div className="title">
            <a href={backArrowLink}> 
              <button
                className="btn btn-primary add"
                onClick={() =>handleBackUrl()}>
                <img src={BackArrow} alt="" />
              </button>
            </a>
            <h1 className="fs-28 mb-0">{ownerInfo?.owner?.name} Price Scenario Manager</h1>
          </div>
          <div
            className={`tab-pane fade show  ${
              isTabActive("v-pills-scenario") ? "active" : ""
            }`}
            id="v-pills-scenario"
            role="tabpanel"
          >
            <SelectPriceScenariotab handleNextStep={handleNext} />
          </div>
          </div>
          
          <div className="price-scenario-tab-container mt-4">
            <div className="tab-data">
              {/* <div
                className="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {[
                  { id: "v-pills-scenario", label: "Select Scenario" },
                  { id: "v-pills-addfuel", label: "Add Price Series" },
                  { id: "v-pills-refineprice", label: "Refine Prices" },
                ].map((tab) => (
                  <button
                    key={tab.id}
                    className={`pe-none nav-link ${
                      isTabActive(tab.id) ? "active" : ""
                    } ${isPreviousTab(tab.id) ? "fill" : ""}`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    <span>{tab.label}</span>
                  </button>
                ))}
              </div> */}
              <div className="tab-content shadow w-100" id="v-pills-tabContent">
                {/* <div
                  className={`tab-pane fade ${
                    isTabActive("v-pills-scenario") ? "show active" : ""
                  }`}
                  id="v-pills-scenario"
                  role="tabpanel"
                >
                  <SelectPriceScenariotab handleNextStep={handleNext} />
                </div> */}
                <div
                  className={`tab-pane show active`}
                  id="v-pills-refineprice"
                  role="tabpanel"
                >
                  <RefinePriceTab handlePrevious={handlePrevious} />
                </div>
                {canAccessRoute("BatchPriceEditor") ? (
                  <div
                    className={`tab-pane show active`}
                    id="v-pills-addfuel"
                    role="tabpanel"
                  >
                    <AddFuelTab
                      handleNextStep={handleNext}
                      handlePrevious={handlePrevious}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FuelPriceForecast;
