import React, {  useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/logo.svg";
import "../css/main.css";
import "../css/responsive.css";
import ZJoule from "../assets/zjoule.png";
import { reactAppConfig } from "../config/reactAppConfig";

const NavbarComponent = ({
  showfaq = false
}) => {

  const infoModalref = useRef();
  const [showModal, setShowModal] = useState(false);
  const [backArrowLink, setBackArrowLink ] = useState("")
  const [appName, setAppName ] = useState("")


  useEffect( () =>{
    handleBackUrl();
  }, []);
  

  const handleBackUrl = () => {
    const url = localStorage.getItem("appUrl")
    const appName = localStorage.getItem("appName")
    setBackArrowLink(url);
    setAppName(appName);
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showModal &&
        infoModalref.current &&
        !infoModalref.current.contains(event.target)
      ) {
        setShowModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showModal, infoModalref]);


  return (
    <Navbar collapseOnSelect expand="lg bottom-border-1px p-0">
      <div className="nav-new-container">
        {appName === "pool" &&
          <Link className="navbar-brand d-flex align-items-center" to={backArrowLink}>
            <span className="span-lg">POOL.FM</span>
            <span className="beta bg-primary ms-3">BETA</span>
          </Link>
        } {appName === "sf2" &&
          <Link className="navbar-brand d-flex align-items-center" to={backArrowLink}>
            <img className="sitelogo" src={Logo} alt="" />
            <span className="beta bg-primary ms-3">BETA</span>
          </Link>
        }
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className= "ms-auto">
            <div className="z-joule-container">
                <p>
                  Powered by{" "}
                </p>
                <span>
                  <NavLink to="https://z-joule.com/" target="_blank">
                    <img src={ZJoule} alt="" />
                  </NavLink>
                </span>
            </div>
          </Nav>
          <Nav className="align-items-center">
            {showfaq && (
              <NavLink to="/faqs" target="_blank">
                <button className="btn btn-secondary faq-button" type="button">
                  FAQs
                </button>
              </NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarComponent;
