import axios from "axios";
import axiosIns from "../api/axios";
import { reactAppConfig } from "../config/reactAppConfig";

export const allLegislativeScenario = () => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/legislation/allScenarios`,
    });
  };


  export const getUserTokenByIdentifier = (identifier) => {
    const url =  reactAppConfig.poolBaseUrl;
    return axios.request({
      method: "GET",
      url: `${url}/api/auth/getIdentifierDetails/${identifier}`,
    });
  };

  export const getOneScenario = (scenarioId) => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/legislation/scenario/${scenarioId}`,
    });
  };

  export const getOwner = () => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/userManagement/getCompany`,
    });
  };

  export const updateScenario = (data) => {
    return axiosIns({
      method: "PUT",
      url: `/api/v2/legislation/upsertScenario`,
      data: data,
    });
  };

  export const regulatoryContext = () => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/legislation/RegulatoryParameters`,
    });
  };

  export const refreshCii = (cii) => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/legislation/refreshCIICurve?z_cii=${cii}`,
    });
  };

  export const addLegislativeScenario = (name) => {
    return axiosIns({
      method: "POST",
      url: `/api/v2/legislation/addScenario?scName=${name}`,
    });
  };

  export const allPriceForecastScenario = () => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/fuelPrices/allScenarios`,
    });
  };

  export const getAllFuelPriceByBasin = (scenarioId, basinId) => {
    return axiosIns({
      method: "GET",
      url: `api/v2/fuelPrices/byBasin/${scenarioId}/${basinId}`,
    });
  };
  
  export const getAllBasinByScenario = (scenarioId) => {
    return axiosIns({
      method: "GET",
      url: `api/v2/fuelPrices/Basins/${scenarioId}`,
    });
  };

  export const getAllFuelTypes = () => {
    return axiosIns({
      method: "GET",
      url: `/api/v2/fuels/fuelTypes/all`,
    });
  };

export const refinePrices = (unit, data) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/fuelPrices/refineScenarioPrices?units=${unit}`,
    data: data,
  });
};

export const graphPrices = (unit, data) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/fuelPrices/refinePriceFeedback?units=${unit}`,
    data: data,
  });
};


export const addFuelPriceScenario = (name) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/fuelPrices/addNewScenario?scName=${name}`,
  });
};

export const getAllBasins = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuelPrices/allBasins`,
  });
};

export const updateTemplate = (data) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/fuelPrices/updateScenarioTemplate`,
    data: data,
  });
};