import { combineReducers } from "redux";
import selectedVesselItemsReducer from "./selectedVesselItemsReducer";
import selectedYearSlice from "./selectedYearSlice";
import selectedLegislativeScenario from "./selectedLegislativeScenario";
import selectedPriceScenario from "./selectedPriceScenario";
import permissionsSlice from "./permissionsSlice";
import revenueValueSlice from "./revenueValueSlice";
import selectedBasin from "./selectedBasin";
import selectedtemplateData from "./selectedtemplateData";
import refineDataSlice from "./refineDataSlice";
import pdfData from "./pdfData";
import emissionSwitchChecked from "./emissionSwitchChecked";
import fuelPurchaseBy from "./fuelPurchaseBy";

const rootReducer = combineReducers({
  selectedVesselItems: selectedVesselItemsReducer,
  selectedYearItem: selectedYearSlice,
  selectedLegislativeScenario: selectedLegislativeScenario,
  permissions: permissionsSlice,
  revenueValue: revenueValueSlice,
  selectedPriceScenario: selectedPriceScenario,
  selectedBasin,
  selectedtemplateData,
  refineDataSlice,
  pdfData,
  emissionSwitchChecked,
  fuelPurchaseBy,
});

export default rootReducer;
