import { useCallback } from "react";

const useNumericInput = () => {
  const handleOnKeyDown = useCallback((event) => {
    const allowedKeyCodes = [8, 46, 37, 39];
    const allowedCharacters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];

    if (allowedKeyCodes.includes(event.keyCode)) {
      return;
    }
    if (allowedCharacters.includes(event.key)) {
      const value = event.target.value;
      const decimalIndex = value.indexOf(".");

      if (event.key === ".") {
        if (decimalIndex === -1) {
          return;
        } else {
          event.preventDefault();
          return;
        }
      }
      if (decimalIndex !== -1) {
        const cursorPosition = event.target.selectionStart;
        const digitsAfterDecimal = value.length - decimalIndex - 1;

        if (cursorPosition > decimalIndex && digitsAfterDecimal >= 1) {
          event.preventDefault();
          return;
        }
      }

      return;
    }
    event.preventDefault();
  }, []);

  return { handleOnKeyDown };
};

export default useNumericInput;
